nav.NavigationBar
{
	position: fixed;
	top:0px;
	left:0px;
	width:100%;
	transition: top 0.3s;
	background-color: rgb(190,22,34);
	z-index: 2;

	&[data-in_launcher = "true"]
	{
		position: relative;
		top:unset;
		left:unset;
		background-color: transparent;
		margin-top: 20px;

		&>div
		{
			margin: 0px;
		}
	}

	&>div
	{
		margin:  0px calc((100vw - 700px)/2);

		&[data-wide = true]
		{
			margin: 0px 120px;
		}


		display: flex;
		align-items: center;
		justify-content: flex-end;

		&>h1.logo
		{
			color:#fff;
			font-size: large;
			margin:0px;
			flex-grow: 1;
			text-align: left;
		}
		
		&>*
		{
			display: block;
			color:#fff;
			padding:10px;
			margin:0px;
			text-align: center;

			&>div.title
			{
				position: relative;
				transition: 0.3s color;
				color:#fff;

				&>div.selector
				{
					width:100%;
					position: absolute;
					bottom: -6px;
					transition: 0.3s all;
					opacity: 0;
					color:#fff;

					&>a
					{
						color:#fff;
						cursor: pointer;

						&:hover
						{
							text-decoration: underline;
						}
					}
				}
			}

			&:hover>div.title
			{
				color:#ffffff00;
				&>div.selector	
				{
					opacity: 1;
					bottom: 0px;
				}
			}

		}
	}
}