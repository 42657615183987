@import '../../common/CommonStyle.scss';




div.CapacityCalendar div.Legend
{
	position: fixed;
	background-color: #000;
	width:100%;
	bottom:0px;
	left:0px;
	z-index: 2;

	&>div.wrapper
	{
		color:white;
		margin:0px 120px;
		padding: 10px 0;

		&>dl
		{
			display: flex;
			padding: 0px;
			margin:0px;

			&>*
			{
				display: block;
				margin: 0px 10px 0px 0px;
			}

			&>dt
			{
				opacity: 0.2;
				transition: 0.3s opacity;
				transition-delay: 0.1s;
			}

			&>dd
			{
				$D: 1.4em;

				width: $D;
				height: $D;
				background: white;

				opacity: 0.2;
				transition: 0.3s opacity;
				transition-delay: 0.1s;

				&[data-highlighted=true]
				{
					opacity: 1;
					transition-delay: 0.1s;

					&+dt
					{
						opacity: 1;
						transition-delay: 0.1s;
					}
				}

				&[data-name=offer-sent]
				{
					border-radius: $D/2;
					background-color: #999;
				}

				&[data-name=offer-received]
				{
					border-radius: $D/2;
					border: 2px solid #999;
				}

				&[data-name=request-sent]
				{
					background-color: #21ba45;
				}

				&[data-name=request-received]
				{
					background-image: bg-ds(rgb(70, 159, 67), 7%, 15);
				}
			}
		}
	}
}