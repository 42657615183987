.adcaption {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 12px;
  z-index: 100;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: absolute;
  bottom: 20px;
  // left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap;
  display: block;
  width: 500px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.adtitle {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  z-index: 100;
  color: white;
  // background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  position: absolute;
  top: 10px;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.carousel .aws-sld__controls {
  --organic-arrow-height: 25px;
  --organic-arrow-thickness: 3px;
  --organic-arrow-color: white;
  --loader-bar-color: #6a6a6a;
}
