.user-list-container {
    display: block;
    margin-top: 100px;
    width: 60vw;
    padding: 10px; 
}

@media screen and (max-width: 1000px) {
    .user-list-container {
        width: 90vw;
    }
}