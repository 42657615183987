.Launcher.root {
  & > .wrapper {
    //background:black;
    padding: 20px calc((100% - 700px) / 6);
    box-sizing: content-box;
    margin: 50px 0px;
    z-index: 1;

    & > h1.logo {
      color: #fff;
      font-size: large;
      margin: 0px;
      flex-grow: 1;
      text-align: left;
      margin: 0px 0px -25px;
      text-shadow: 3px 3px 7px #000;
    }

    & > svg {
      margin-bottom: 40px;
      & > path {
        fill: none;
        stroke: #ffffff;
        stroke-width: 4;
        stroke-opacity: 1;
      }
    }
  }

  & section {
    width: 100%;

    & > header {
      color: white;
      font-size: xx-large;
      font-weight: lighter;
      padding: 20px 00px;
      margin: 20px 0px 20px;
      position: relative;
      text-shadow: 0px 1px 5px #000;
      background-color: rgba(129, 129, 129, 0);
      //border-top: 1px solid white;
    }

    & > div.links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -20px;

      & > a {
        flex-grow: 1;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: center;

        min-width: 200px;
        min-height: 150px;
        text-align: center;
        background-color: rgba(129, 129, 129, 0);
        margin: 0px 20px 20px 0px;
        margin-right: 20px;

        transition: 0.2s background-color, 0.2s border-color;
        border: 2px solid rgb(255, 255, 255);

        transition-delay: 0.1s, 0.1s;

        &[href='#invalid'],
        &:not([href]) {
          color: #aaa;
          font-weight: lighter;
          text-decoration: line-through;
          font-style: italic;
        }

        &:hover {
          border-color: rgb(255, 255, 255);
          background-color: rgba(129, 129, 129, 0.6);
          transition-delay: 0s, 0s;
          & > div {
            max-height: 100px;
          }
        }

        & > header {
          font-size: x-large;
        }

        & > div {
          transition: 0.3s max-height;
          max-height: 0px;
          overflow: hidden;
        }
      }
    }
  }
}

.Launcher > .Wallpaper {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: black;

  &:hover {
    & > img {
      opacity: 1 !important;
      transition-delay: 0.4s;
    }
    & > div.info {
      opacity: 1 !important;
      transition-delay: 0.4s;
    }
    & ~ div.wrapper {
      opacity: 0;
      transition-delay: 0.4s;
      transition: opacity 0.8s;
    }
  }

  & ~ div.wrapper {
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 0s;
  }

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: opacity 0.3s;
    transition-delay: 0s;
  }

  & > div.info {
    position: fixed;
    bottom: 10px;
    left: 10px;

    width: 100%;
    max-width: 500px;
    color: white;
    background-color: black;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.4s;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-trigger {
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }

  p {
    display: block;
    width: 100%;
    background: black;
    color: white;
    position: absolute;
    bottom: 0px;
    text-indent: 5px;
    padding: 4px 0;
    opacity: 0.7;
    font-size: 12px;
  }
  &:hover {
    box-shadow: 0px 0px 8px #999;
    transition: all 0.3s ease-in-out;
    p {
      opacity: 1;
    }
  }
}
