.ApplicationCollection,
.ApplicationCollection>div.root
{
	width: 100vw;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&>.wrapper
	{
		flex-grow: 1;
		max-width: 700px;
		margin:  100px 0px;

		&>div.cards
		{
			margin: 0px;
			display: grid;
			grid-template-columns:1fr 1fr;
			grid-column-gap:5%;
			grid-row-gap: 40px;

			@media screen and (max-width: 400px)
			{
				grid-template-columns:1fr;
			}

			&>div.card,
			&>.segment
			{
				margin:0px;
			}
		}
	}
}



footer.SurfAsCompany
{
	position: fixed;
	left:0px;
	bottom: 0px;
	width:100%;
	padding:5px;
	background-color: #333;

	&.warning
	{
		background-color: violet;
	}
}



textarea
{
	resize: none !important;
}