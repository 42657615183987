@import "./WeekSpan.scss" , "./Legend.scss";

$WEEK_BORDER:1px solid #ddd;




div.CapacityCalendar
{
	margin:120px;
	flex-grow: 1;
	user-select: none;
	overflow: hidden;

	&>div.weeks
	{
		&>.header
		{
			color:#999;
			font-weight: lighter;
			margin: 1em 0px;
		}

		&>div.content
		{
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	
			border-top:$WEEK_BORDER;
			border-left:$WEEK_BORDER;
		}
	}

	&>.renderNextYearButton
	{
		margin: 50px 0px;
		display: flex;
		justify-content: center;
	}
}




div.CapacityCalendar div.Week
{
	border-right:$WEEK_BORDER;
	border-bottom:$WEEK_BORDER;

	color:#999;
	user-select: none;

	&[data-selected = true]
	{
		background-color: #f7f7f7 !important;
		color:#333;
	}

	&[data-break = true]
	{
		border-left: $WEEK_BORDER;
		border-left-width: 3px;
	}

	&[data-sign = "0"]
	{
		background-color: rgb(255, 207, 214);

		&:hover { color:#111; }
	}

	&[data-sign = "1"]
	{
		background-color: rgb(255, 255, 255);

		&:hover { color:#111; }
	}
	
	&[data-sign = "-1"]
	{
		background-color: rgba(0,0,0, 0.05);

		&>div.header { font-style: italic; }
		&>footer { font-style: italic; }
	}

	&>div.header
	{
		padding: 5px;
	}

	&>div.spans
	{
		position: relative;
		min-height: 100px;
	}

	&>footer
	{
		text-align: center;
	}
}