@import '../../common/CommonStyle.scss';

div.CapacityCalendar div.WeekSpan
{
	$HEIGHT: 23px;
	$GREEN: #21ba45;
	$GREY: #8f8f8f;
	$RED: #e65400;

	height:$HEIGHT;

	position: absolute;
	cursor: pointer;
	opacity: 0.3;
	transition: 0.3s opacity;


	&[data-highlighted = true]{	opacity: 1 !important;	}
	&[data-is_in_filter = false]{ opacity: 0 !important;	}


	&[data-start = true][data-end = false]	{	right:	0px;	width: 80%;		z-index: 1;}
	&[data-start = false][data-end = true]	{	left:	0px;	width: 80%;		}
	&[data-start = true][data-end = true]	{	right:	10%;	width: 80%;		}
	&[data-start = false][data-end = false]	{	left:	0px;	width: 100%;	}

	
	&[data-type=offer]
	{
		$R: 10px;

		&[data-start = true][data-end = false]	{	border-radius: $R 0 0 $R;	}
		&[data-start = false][data-end = true]	{	border-radius: 0 $R $R 0;	}
		&[data-start = true][data-end = true]	{	border-radius: $R;			}

		&[data-dir=out]>div>div.label
		{
			border-radius: $R;
		}
	}


	&[data-dir=out]
	{
		&[data-status="0"]{background-color: $GREY	!important;}
		&[data-status="1"]{background-color: $GREEN !important;}
		&[data-status="2"]{background-color: $RED	!important;}

		&[data-status="0"]>div>div.label {background-color: darken($GREY,	20%) !important;}
		&[data-status="1"]>div>div.label {background-color: darken($GREEN,	15%) !important;}
		&[data-status="2"]>div>div.label {background-color: darken($RED,	15%) !important;}

		&>div
		{
			display: flex;
			align-items: center;
			color:white;

			div.label
			{
				min-width: $HEIGHT;
				text-align: center;
				font-weight: bold;
				height: 100%;
			}

			div.name
			{
				padding-left: 5px;
				white-space: nowrap;
			}
		}
	}


	&[data-dir=in]
	{
		background-attachment: fixed;

		&[data-status="0"]{ background-image: bg-ds($GREY,	5%); }
		&[data-status="1"]{ background-image: bg-ds($GREEN, 6%); }
		&[data-status="2"]{ background-image: bg-ds($RED,	5%); }

		&>div
		{
			display: flex;
			align-items: center;
			color:white;

			div.name, div.label{ padding-left: 5px; }

			div.name
			{
				white-space: nowrap;
			}
		}
	}

	&>div
	{
		width:100%;
		height:100%;
	}
}
