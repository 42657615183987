@function str-replace($string, $search, $replace: '')
{
	$index: str-index($string, $search);

	@if $index
	{
	  @return	str-slice($string, 1, $index - 1)
				+ $replace
				+ str-replace(
					str-slice($string, $index + str-length($search)),
					$search,
					$replace
				);
	}

	@return $string;
}




$encoding-reference:
(
	('<','%3C'),
	('>','%3E'),
	('"','\''),
	('#','%23'),
	('&','%26')
);




@function svg-encode($svg)
{
	@each $char, $encoded in $encoding-reference
	{
		$svg: str-replace($svg, $char, $encoded);
	}

	@return 'data:image/svg+xml,' + $svg;
}




@function svg-ds($w, $h, $ca, $cb)
{
	@return "<svg xmlns='http://www.w3.org/2000/svg' width='"
			+ $w
			+ "' height='"
			+ $h
			+ "' viewBox='0 0 20 20'><rect fill='"
			+ $ca
			+ "' width='20' height='20'/><g ><polygon fill='"
			+ $cb
			+ "' points='20 10 10 0 0 0 20 20'/><polygon fill='"
			+ $cb
			+ "' points='0 10 0 20 10 20'/></g></svg>";
}




@function bg-ds($color, $darken, $size: 20)
{
	@return url(
		svg-encode(
			svg-ds(
				$size, $size, $color, darken($color, $darken)
			)
		)
	);
}